import { useState, useEffect } from 'react'

import {
    Select,
    MenuItem,
    InputLabel,
    TextField
} from '@mui/material'

import { GetAllTags } from '../../../../../actions';


const ScorecardConfiguration = ({ id, title,
    isConfigDrawerOpen, 
    toggleConfigDrawer, 
    widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

    const [selectedTagValue, setSelectedTagValue] = useState(widgetAdvanceSettings?.scorecard?.selectedTagValue)
    const [selectedReducerFunctionIndex, setSelectedReducerFunctionIndex] = useState(widgetAdvanceSettings?.scorecard?.selectedReducerFunctionIndex)
    const [textSize, setTextSize] = useState(widgetAdvanceSettings?.scorecard?.textSize)
    const [tags, setTags] = useState([]);

    useEffect(async () => {
        await GetAllTags(setTags);
    }, [])

    useEffect(() => {
        console.log('textSize')
        console.log(textSize)
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            scorecard: {
                selectedTagValue: selectedTagValue,
                selectedReducerFunctionIndex: selectedReducerFunctionIndex,
                textSize: textSize
            }
        })
    }, [selectedTagValue, selectedReducerFunctionIndex, textSize])

    const setSelectedTagValueFunc = async (tagName) => {
        setSelectedTagValue(tagName)
    }

    const setReducerFunction = (val) => {
        if(tags.length == 0)
            return;
        setSelectedReducerFunctionIndex(val)
    }

    const updateTextSize = (size) => {
        setTextSize(size > 200 ? 200: size)
    }

    return <div>
        <InputLabel id="selectTagId">Select tag</InputLabel>
        <Select
            value={widgetAdvanceSettings?.scorecard?.selectedTagValue == null ? '' : widgetAdvanceSettings?.scorecard?.selectedTagValue}
            variant='standard'
            size='small'
            fullWidth={true}
            onChange={(e) => setSelectedTagValueFunc(e.target.value)
            }
            >
                {tags.map(tag => {
                    return <MenuItem 
                    key={tag.id} 
                    value={tag.name}
                    >
                        {tag.name}
                    </MenuItem>    
                })}
                
            </Select>
        
            <InputLabel id="reducerFunctionId">Choose a reducer function / calculation</InputLabel>
            <Select
            value={widgetAdvanceSettings?.scorecard?.selectedReducerFunctionIndex == null ? -1 : widgetAdvanceSettings?.scorecard?.selectedReducerFunctionIndex}
            variant='standard'
            size='small'
            fullWidth={true}
             onChange={(e) => setReducerFunction(e.target.value)
            }
            >
                <MenuItem value={1}>Mean</MenuItem>
                <MenuItem value={2}>Last value</MenuItem>
                <MenuItem value={3}>First value</MenuItem>
                <MenuItem value={4}>Min</MenuItem>
                <MenuItem value={5}>Max</MenuItem>
            </Select>

            <InputLabel id="textSizeId">Text size</InputLabel>
            <TextField 
            value={widgetAdvanceSettings?.scorecard?.textSize == 10 ? -1 : widgetAdvanceSettings?.scorecard?.textSize}
            variant="outlined"
            fullWidth={true}
            onChange={(e) => updateTextSize(e.target.value)}></TextField>
    </div>
}

export default ScorecardConfiguration