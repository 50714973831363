import axios from 'axios'
import { ApiEndpoint } from "config"

import { updateNavigation } from 'reducers/global/actions'
import { showNotification } from 'reducers/notification/actions'

import store from 'reducers'

export const GetAllNavigation = async () => {

    const accessToken = store.getState().authentication?.token?.accessToken

    if (accessToken) {
        await axios.get(ApiEndpoint.Page.GetAllNavigation, {
            headers: {
                'authorization': `bearer ${accessToken}`
            }
        }).then(resp => {
            store.dispatch(updateNavigation(resp.data))
        }).catch(err => {
            store.dispatch(showNotification(err.message ?? err.response.data, 'danger'))
        })
    }
}
