
import React, { useEffect, useState } from 'react';


import {
    ListItem,
    ListItemText,
    Collapse
} from '@mui/material'

import {
    Key as KeyIcon
} from '@mui/icons-material'

import PowerBiConfiguration from 'pages/dashboard/widgetConfigurationDrawer/Configuration/AdvanceConfiguration/powerBiConfiguration'
import KustoConfiguration from './AdvanceConfiguration/kustoConfiguration';
import LineConfiguration from './AdvanceConfiguration/lineConfiguration';
import ImageConfiguration from './AdvanceConfiguration/imageConfiguration';
import FFTConfiguration from './AdvanceConfiguration/fftConfiguration';
import SpectrogramConfiguration from './AdvanceConfiguration/spectrogramConfiguration';
import WaterfallConfiguration from './AdvanceConfiguration/waterfallConfiguration';
import PieConfiguration from './AdvanceConfiguration/pieConfiguration';
import SurfaceChartConfiguration from './AdvanceConfiguration/SurfaceChartConfiguration';
import ScorecardConfiguration from './AdvanceConfiguration/scorecardConfiguration';
// import LabelConfiguration from './AdvanceConfiguration/labelConfiguration';
import LabelConfiguration from './AdvanceConfiguration/labelConfiguration';
import AreaConfiguration from './AdvanceConfiguration/areaConfiguration';
import ScatterConfiguration from './AdvanceConfiguration/scatterConfiguration';
import ButtonConfiguration from './AdvanceConfiguration/buttonConfiguration';
import BarConfiguration from './AdvanceConfiguration/barConfiguration';



const WidgetSpecficConfig = ({ id, isConfigDrawerOpen, toggleConfigDrawer, isWidgetAdvanceOpen,
    widgetType, widgetAdvanceSettings, setWidgetAdvanceSettings, ...props }) => {



    const specialWidgetConfig = () => {
        switch (widgetType) {
            case 'line':
                return <LineConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'bar':
                return <BarConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'button':
                return <ButtonConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            
            case 'label':
                return <LabelConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'area':
                return <AreaConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            
            case 'pie':
                return <PieConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'scatter':
                return <ScatterConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            
            case 'image':
                return <ImageConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

        
            case 'fft':
                return <FFTConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            
            case 'spectrogram':
                return <SpectrogramConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

        
            case 'waterfall':
                return <WaterfallConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'power-bi':
                return <PowerBiConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />


            case 'kusto':
                return <KustoConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />

            case 'surfaceChart':
                return <SurfaceChartConfiguration
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props} />
            case 'scorecard':
                return <ScorecardConfiguration 
                    id={id}
                    widgetAdvanceSettings={widgetAdvanceSettings}
                    setWidgetAdvanceSettings={setWidgetAdvanceSettings}
                    {...props}
                />

            case null:
                return <></>


            default:
                return <ListItem id={'config-list-item'} className={'config-list-item'}>
                    <ListItemText>Under developing ... Please be patient</ListItemText>
                </ListItem>
        }

    }


    return <Collapse in={isWidgetAdvanceOpen} timeout="auto" unmountOnExit >

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <KeyIcon />
            <ListItemText primary={id} className='config-menu-item-text' />
        </ListItem>

        {specialWidgetConfig()}



    </Collapse>

}


export default WidgetSpecficConfig;
